import React from "react";

export default function PaymentCancel() {
  return (
    <div style={{ padding: "2rem" }}>
      <h1>Payment Cancelled!</h1>
      <p>Please go back to your business.</p>
    </div>
  );
}
