import React from "react";

export default function Info() {
  return (
    <div className="container">
      <div className="row my-5">
        <div className="col">
          <h1 className="blueText">What is ThemUs?</h1>
          <p className="mt-4">
            ThemUs is a news and debate platform where both sides of the
            argument have equal prominence.
          </p>
          <p>
            This project is in it's infancy. Please be patient while things get
            better.
          </p>
          <p>Keep coming back for improvements and new features...</p>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <h5 className="blueText">Credits</h5>
          <a
            href="https://www.flaticon.com/free-icons/uk-flag"
            title="uk flag icons"
            className="text-decoration-none offBlackText"
          >
            Uk flag icons created by Freepik - Flaticon
          </a>
        </div>
      </div>
    </div>
  );
}
